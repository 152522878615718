import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportService from "./reports-service";
import Utility from "../../../shared/utility";
import exportLimit from "../../common/export-limit.vue";
import moment from "moment";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      max50Rule: [(v) => (v || "").length <= 30 || "Max Length of 50 character", (v) => !!v || "Field is required"],
      assetID: "",
      orderInfo: "",
      trackingInfo: "",
      searchAssetHistory: "",
      assetHistoryList: [],
      totalRecords: 0,
      showData: false,
      page: 1,
      pageCount: 0,
      showDetails: false,
      attributeData: [],
      latestVisitData: [],
      visitData: [],
      totalAttributeData: [],
      totalLatestVisitData: [],
      detailsPopup: false,
      popup1Data: [],
      vendorData: [],
      customerData: [],
      partData: [],
      workCenterData: [],
      warehouseData: [],
      binData: [],
      issueData: [],
      pickTicketData: [],
      btoPartData: [],
      typePopup2Data: "",
      tabsAvlbl: 0,
      excelName: "",
      exportDisable: true,
      showBack: false,
      json_fields: {
        Project: "project",
        Customer: "customer",
        Vendor: "vendor",
        "Part No": "part_num",
        BCN: "bcn",
        "Serial No": "serialno",
        ESN: "esn",
        "RLP ID": "license_plate",
        "Search Key": "search_param",
      },
      viewDetObj: {
        user_id: 0,
        unit: "",
        type: 0,
        detbit: 0,
        tran_key: 0,
        ship_id: 0,
        pi_scan_id: 0,
        wip_id: 0,
        kit_id: 0,
        kit_type: "",
        dsc_line_id: 0,
      },
      apiObj: {
        user_key1: 0,
        orderinformation: "",
        assetids: "",
        trackinginformation: "",
        testbit: 0,
        RecordCount: 0,
        PageNumber: 0,
        guid: "",
      },
      assetHistoryHeaders: [
        { text: "Project", value: "project", class: "primary customwhite--text" },
        { text: "Customer", value: "customer", class: "primary customwhite--text" },
        { text: "Vendor", value: "vendor", class: "primary customwhite--text" },
        { text: "Part No", value: "part_num", class: "primary customwhite--text" },
        { text: "BCN", value: "bcn", class: "primary customwhite--text" },
        { text: "Serial No", value: "serialno", class: "primary customwhite--text" },
        { text: "ESN", value: "esn", class: "primary customwhite--text" },
        { text: "RLP ID", value: "license_plate", class: "primary customwhite--text" },
        { text: "Search Key", value: "search_param", class: "primary customwhite--text" },
      ],
      latestVisitHeaders: [
        { text: "Work Station", value: "workstation", class: "primary customwhite--text", align: "start" },
        { text: "", value: "details", class: "primary customwhite--text" },
        { text: "Date Time", value: "datetimer", class: "primary customwhite--text" },
      ],
    };
  },
  computed: {
    descendingNumbers() {
      const numbers = [];
      for (let i = this.tabsAvlbl; i >= 1; i--) {
        numbers.push(i);
      }
      return numbers;
    },
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.assetID = "";
      this.orderInfo = "";
      this.trackingInfo = "";
      this.searchAssetHistory = "";
      this.assetHistoryList = [];
      this.totalRecords = 0;
      this.showData = false;
      this.page = 1;
      this.pageCount = 0;
      this.showDetails = false;
      this.attributeData = [];
      this.latestVisitData = [];
      this.visitData = [];
      this.totalAttributeData = [];
      this.totalLatestVisitData = [];
      this.detailsPopup = false;
      this.popup1Data = [];
      this.vendorData = [];
      this.customerData = [];
      this.partData = [];
      this.workCenterData = [];
      this.warehouseData = [];
      this.binData = [];
      this.issueData = [];
      this.pickTicketData = [];
      this.btoPartData = [];
      this.typePopup2Data = "";
      this.tabsAvlbl = 0;
      this.excelName = "";
      this.exportDisable = true;
      this.showBack = false;
      this.viewDetObj = {
        user_id: 0,
        unit: "",
        type: 0,
        detbit: 0,
        tran_key: 0,
        ship_id: 0,
        pi_scan_id: 0,
        wip_id: 0,
        kit_id: 0,
        kit_type: "",
        dsc_line_id: 0,
      };
      this.apiObj = {
        user_key1: 0,
        orderinformation: "",
        assetids: "",
        trackinginformation: "",
        testbit: 0,
        RecordCount: 0,
        PageNumber: 0,
        guid: "",
      };
      this.$refs.assetSearchForm.resetValidation();
    },
    //Asset ID Search
    async searchAssetID() {
      if (this.assetID !== undefined && this.assetID !== "") {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.assetids = this.assetID;
        this.apiObj.PageNumber = 1;
        this.apiObj.guid = "";
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/asset_history_rpt_load_grid_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              if (responseData?.length !== 0) {
                this.totalRecords = responseData[0].TotalRecords;
                this.pageCount = responseData[0].TotalPages;
                this.$refs.assetSearchForm.resetValidation();
                if (responseData[0].TotalRecords == 0) {
                  this.assetHistoryList = [];
                  this.showData = false;
                  this.showDetails = false;
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "No data available based on criteria",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.exportDisable = false;
                  this.assetHistoryList = responseData;
                  this.showData = true;
                  this.showDetails = false;
                  if (responseData[0].TotalRecords == 1) {
                    let keyData = this.getMatchingKeys(responseData[0]);
                    this.viewDetails(responseData[0], keyData);
                  }
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetHistoryList = [];
        this.showDetails = false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Input event of Asset ID
    inputAssetID() {
      this.assetID = this.assetID.toUpperCase();
      this.trackingInfo = "";
      this.orderInfo = "";
    },
    //Order Information Search
    async searchOrderInfo() {
      if (this.orderInfo !== undefined && this.orderInfo !== "") {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.orderinformation = this.orderInfo;
        this.apiObj.PageNumber = 1;
        this.apiObj.guid = "";
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/asset_history_rpt_load_grid_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              if (responseData?.length !== 0) {
                this.totalRecords = responseData[0].TotalRecords;
                this.pageCount = responseData[0].TotalPages;
                this.$refs.assetSearchForm.resetValidation();
                if (responseData[0].TotalRecords == 0) {
                  this.assetHistoryList = [];
                  this.showData = false;
                  this.showDetails = false;
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "No data available based on criteria",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.exportDisable = false;
                  this.assetHistoryList = responseData;
                  this.showData = true;
                  this.showDetails = false;
                  if (responseData[0].TotalRecords == 1) {
                    let keyData = this.getMatchingKeys(responseData[0]);
                    this.viewDetails(responseData[0], keyData);
                  }
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetHistoryList = [];
        this.showDetails = false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Input event of Order Information
    inputOrderInfo() {
      this.orderInfo = this.orderInfo.toUpperCase();
      this.trackingInfo = "";
      this.assetID = "";
    },
    //Tracking Information Search
    async searchTrackingInfo() {
      if (this.trackingInfo !== undefined && this.trackingInfo !== "") {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.trackinginformation = this.trackingInfo;
        this.apiObj.PageNumber = 1;
        this.apiObj.guid = "";
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/asset_history_rpt_load_grid_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              if (responseData?.length !== 0) {
                this.totalRecords = responseData[0].TotalRecords;
                this.pageCount = responseData[0].TotalPages;
                this.$refs.assetSearchForm.resetValidation();
                if (responseData[0].TotalRecords == 0) {
                  this.assetHistoryList = [];
                  this.showData = false;
                  this.showDetails = false;
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "No data available based on criteria",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.exportDisable = false;
                  this.assetHistoryList = responseData;
                  this.showData = true;
                  this.showDetails = false;
                  if (responseData[0].TotalRecords == 1) {
                    let keyData = this.getMatchingKeys(responseData[0]);
                    this.viewDetails(responseData[0], keyData);
                  }
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetHistoryList = [];
        this.showDetails = false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Input event of Tracking
    inputTracking() {
      this.trackingInfo = this.trackingInfo.toUpperCase();
      this.orderInfo = "";
      this.assetID = "";
    },
    //On search button click
    async onSearch(page) {
      this.apiObj = {
        user_key1: 0,
        orderinformation: "",
        assetids: "",
        trackinginformation: "",
        testbit: 0,
        RecordCount: 0,
        PageNumber: 0,
        guid: "",
      };
      if (this.assetID.length !== 0) {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.assetids = this.assetID;
        this.apiObj.guid = "";
        if (page !== undefined) {
          this.apiObj.PageNumber = page;
        } else {
          this.apiObj.PageNumber = 1;
        }
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/asset_history_rpt_load_grid_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              if (responseData?.length !== 0) {
                this.totalRecords = responseData[0].TotalRecords;
                this.pageCount = responseData[0].TotalPages;
                this.$refs.assetSearchForm.resetValidation();
                if (responseData[0].TotalRecords == 0) {
                  this.assetHistoryList = [];
                  this.showData = false;
                  this.showDetails = false;
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "No data available based on criteria",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.exportDisable = false;
                  this.assetHistoryList = responseData;
                  this.showData = true;
                  this.showDetails = false;
                  if (responseData[0].TotalRecords == 1) {
                    let keyData = this.getMatchingKeys(responseData[0]);
                    this.viewDetails(responseData[0], keyData);
                  }
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
      if (this.orderInfo.length !== 0) {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.orderinformation = this.orderInfo;
        this.apiObj.guid = "";
        if (page !== undefined) {
          this.apiObj.PageNumber = page;
        } else {
          this.apiObj.PageNumber = 1;
        }
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/asset_history_rpt_load_grid_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              if (responseData?.length !== 0) {
                this.totalRecords = responseData[0].TotalRecords;
                this.pageCount = responseData[0].TotalPages;
                this.$refs.assetSearchForm.resetValidation();
                if (responseData[0].TotalRecords == 0) {
                  this.assetHistoryList = [];
                  this.showData = false;
                  this.showDetails = false;
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "No data available based on criteria",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.exportDisable = false;
                  this.assetHistoryList = responseData;
                  this.showData = true;
                  this.showDetails = false;
                  if (responseData[0].TotalRecords == 1) {
                    let keyData = this.getMatchingKeys(responseData[0]);
                    this.viewDetails(responseData[0], keyData);
                  }
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
      if (this.trackingInfo.length !== 0) {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.trackinginformation = this.trackingInfo;
        this.apiObj.guid = "";
        if (page !== undefined) {
          this.apiObj.PageNumber = page;
        } else {
          this.apiObj.PageNumber = 1;
        }
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/asset_history_rpt_load_grid_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              if (responseData?.length !== 0) {
                this.totalRecords = responseData[0].TotalRecords;
                this.pageCount = responseData[0].TotalPages;
                this.$refs.assetSearchForm.resetValidation();
                if (responseData[0].TotalRecords == 0) {
                  this.assetHistoryList = [];
                  this.showData = false;
                  this.showDetails = false;
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "No data available based on criteria",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.exportDisable = false;
                  this.assetHistoryList = responseData;
                  this.showData = true;
                  this.showDetails = false;
                  if (responseData[0].TotalRecords == 1) {
                    let keyData = this.getMatchingKeys(responseData[0]);
                    this.viewDetails(responseData[0], keyData);
                  }
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //View Details
    async viewDetails(item, type) {
      if (type == "bcn") {
        let obj = {
          type: 1,
          user_id: parseInt(this.userId),
          unit: item.bcn,
          detbit: 0,
          tran_key: 0,
          ship_id: 0,
          pi_scan_id: 0,
          wip_id: 0,
          kit_id: 0,
          kit_type: "",
          dsc_line_id: 0,
        };
        this.viewDetObj = obj;
      } else if (type == "serialno") {
        let obj = {
          type: 2,
          user_id: parseInt(this.userId),
          unit: item.serialno,
          detbit: 0,
          tran_key: 0,
          ship_id: 0,
          pi_scan_id: 0,
          wip_id: 0,
          kit_id: 0,
          kit_type: "",
          dsc_line_id: 0,
        };
        this.viewDetObj = obj;
      } else if (type == "esn") {
        let obj = {
          type: 3,
          user_id: parseInt(this.userId),
          unit: item.esn,
          detbit: 0,
          tran_key: 0,
          ship_id: 0,
          pi_scan_id: 0,
          wip_id: 0,
          kit_id: 0,
          kit_type: "",
          dsc_line_id: 0,
        };
        this.viewDetObj = obj;
      } else if (type == "licenseplate") {
        let obj = {
          type: 4,
          user_id: parseInt(this.userId),
          unit: item.license_plate,
          detbit: 0,
          tran_key: 0,
          ship_id: 0,
          pi_scan_id: 0,
          wip_id: 0,
          kit_id: 0,
          kit_type: "",
          dsc_line_id: 0,
        };
        this.viewDetObj = obj;
      }
      let showMessage = false;
      let data = await reportService.assetHistoryNewDetails("post", this.viewDetObj, showMessage);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.attributeData = [];
          this.latestVisitData = [];
          const newArr = data.Resultset1.map((obj) => {
            return {
              ...obj,
              datetimer: Utility.convertESTToLocal(obj.datetimer),
            };
          });
          this.totalAttributeData = data.Resultset;
          this.totalLatestVisitData = newArr;
          this.totalAttributeData.forEach((item) => {
            if (item.visit > this.tabsAvlbl) {
              this.tabsAvlbl = item.visit - 1;
            }
          });
          let attrData = this.totalAttributeData;
          this.attributeData = attrData.filter((obj) => obj.visit == this.tabsAvlbl + 1);
          let visData = this.totalLatestVisitData;
          this.latestVisitData = visData.filter((obj) => obj.visit == this.tabsAvlbl + 1);
          this.showDetails = true;
          this.showData = false;
          if (this.assetHistoryList.length == 1) {
            this.showBack = false;
          } else {
            this.showBack = true;
          }
        } else {
          this.showDetails = false;
          this.showData = true;
          this.showBack = false;
        }
      } else {
        this.showDetails = false;
        this.showData = true;
        this.showBack = false;
      }
    },
    //Visit Details
    async visitDetails(item) {
      this.viewDetObj = {
        type: parseInt(this.viewDetObj.type),
        user_id: parseInt(this.userId),
        unit: this.viewDetObj.unit,
        detbit: 1,
        tran_key: parseInt(item.tran_id),
        ship_id: parseInt(item.ship_id),
        pi_scan_id: parseInt(item.pi_scan_id),
        wip_id: parseInt(item.wip_id),
        kit_id: parseInt(item.kit_id),
        kit_type: item.kit_type,
        dsc_line_id: 0,
      };
      let showMessage = false;
      let apiData = await reportService.assetHistoryNewDetails("post", this.viewDetObj, showMessage);
      this.visitData = [];
      this.visitData = apiData.Resultset2;
      for (let i = 0; i < this.visitData.length; i++) {
        if (
          this.visitData[i].caption == "Receive Date" ||
          this.visitData[i].caption == "Scan Date" ||
          this.visitData[i].caption == "Transaction Date" ||
          this.visitData[i].caption == "Ship Date"
        ) {
          if (this.visitData[i]?.value !== "" && this.visitData[i]?.value !== undefined) {
            this.visitData[i].value = Utility.convertESTToLocal(this.visitData[i]?.value);
          }
        }
      }
      let data = this.visitData;
      this.popup1Data = data.filter((obj) => obj.tran_key == item.tran_id);
      this.popup1Data = this.popup1Data.filter((obj) => {
        return !(obj.query === "" && obj.value === "View");
      });
      this.popup1Data = this.popup1Data.map((item) => ({
        ...item,
        showDetails: false,
        showVendor: false,
        showCustomer: false,
        showPart: false,
        showWorkCenter: false,
        showWarehouse: false,
        showBin: false,
        showIssues: false,
        showPickTicket: false,
        showBTOParts: false,
        notes: "",
        details: [],
      }));
      this.detailsPopup = true;
    },
    //Close Dialog
    closeDialog() {
      this.detailsPopup = false;
      this.popup1Data = [];
      this.vendorData = [];
      this.customerData = [];
      this.partData = [];
      this.workCenterData = [];
      this.warehouseData = [];
      this.binData = [];
      this.issueData = [];
      this.pickTicketData = [];
      this.btoPartData = [];
    },
    //Caption Details
    async captionDetails(item) {
      item.showDetails = !item.showDetails;
      const match = item.drill_query.match(/@([^=]+)=([^@]+)/);
      const spname = item.drill_query.match(/exec\s+(\w+\.\w+)\s+(.+)/);
      if (match[1] == "po_id") {
        item.showVendor = !item.showVendor;
        let data = await reportService.assetHistoryVendor("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.vendorData = data;
          }
        }
      } else if (match[1] == "so_id") {
        item.showCustomer = !item.showCustomer;
        let data = await reportService.assetHistoryCustomer("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.customerData = data;
          }
        }
      } else if (match[1] == "part_id") {
        item.showPart = !item.showPart;
        let data = await reportService.assetHistoryPart("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.partData = data;
          }
        }
      } else if (match[1] == "loc") {
        item.showWorkCenter = !item.showWorkCenter;
        let data = await reportService.assetHistoryWorkCenter("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.workCenterData = data;
          }
        }
      } else if (match[1] == "ware_id") {
        item.showWarehouse = !item.showWarehouse;
        let data = await reportService.assetHistoryWarehouse("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.warehouseData = data;
          }
        }
      } else if (match[1] == "bin_id") {
        item.showBin = !item.showBin;
        let data = await reportService.assetHistoryBin("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.binData = data;
          }
        }
      } else if (match[1] == "wip_id") {
        if (spname[1].substring(4) == "usp_Rx_RT_asset_history_drill_issues") {
          item.showIssues = !item.showIssues;
          let data = await reportService.assetHistoryDrillIssues("get", match[2]);
          if (data !== undefined) {
            if (data.message !== "NA") {
              this.issueData = data;
            }
          }
        } else if (spname[1].substring(4) == "usp_Rx_RT_asset_history_drill_PT") {
          item.showPickTicket = !item.showPickTicket;
          let data = await reportService.assetHistoryDrillPickTicket("get", match[2]);
          if (data !== undefined) {
            if (data.message !== "NA") {
              this.pickTicketData = data;
            }
          }
        }
      } else if (match[1] == "kit_id") {
        item.showBTOParts = !item.showBTOParts;
        let data = await reportService.assetHistoryDrillBTOParts("get", match[2]);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.btoPartData = data;
          }
        }
      }
    },
    //Part number format function
    formatCreatedDate(text) {
      let parts = text.split(" , ");
      let date = parts[0].replace("Created:", "").trim();
      let name = parts[1].trim();
      date = Utility.convertESTToLocal(date);
      date = moment(date).format("MM/DD/YYYY");
      return `Created: ${date} By: ${name}`;
    },
    //Remove Caption Details
    removeCaptionDetails(item) {
      item.details = [];
      item.showDetails = false;
      item.showVendor = false;
      item.showCustomer = false;
      item.showPart = false;
      item.showWorkCenter = false;
      item.showWarehouse = false;
      item.showBin = false;
      item.showIssues = false;
      item.showPickTicket = false;
      item.showBTOParts = false;
    },
    //Latest Visit
    latestVisit() {
      let visitVal = this.tabsAvlbl + 1;
      let attrData = this.totalAttributeData;
      this.attributeData = attrData.filter((obj) => obj.visit == visitVal);
      let visData = this.totalLatestVisitData;
      this.latestVisitData = visData.filter((obj) => obj.visit == visitVal);
    },
    //Visit Button click event
    clickVisit(i) {
      this.attributeData = [];
      this.latestVisitData = [];
      let attrData = this.totalAttributeData;
      this.attributeData = attrData.filter((obj) => obj.visit == i);
      let visData = this.totalLatestVisitData;
      this.latestVisitData = visData.filter((obj) => obj.visit == i);
    },
    //Get waybill params
    async getParamsWaybill(queryString) {
      const regex = /@sl_code='([^']+)'.*@waybill='([^']+)'/;
      const match = queryString.match(regex);
      let data = await reportService.assetHistoryWaybillLink("get", match[1], match[2]);
      if (data !== undefined) {
        if (data.message !== "NA") {
          const regex = /href="([^"]+)"/;
          const match = data[0].urlstring.match(regex);
          window.open(match[1], "_blank");
        }
      }
    },
    //Get Notes section
    async showNotes(queryString, trackId) {
      const regex = /@pass=(\d+),@query_id=(\d+)/;
      const match = queryString.match(regex);
      let data = await reportService.assetHistoryNoteQuery("get", match[1], match[2]);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.popup1Data.forEach((obj) => {
            if (obj.track_id === trackId) {
              obj.notes = data[0].query_val;
            }
          });
        }
      }
    },
    //Page Level Back Event
    pageLvlBack() {
      this.showData = true;
      this.showDetails = false;
      this.tabsAvlbl = 0;
      this.attributeData = [];
      this.latestVisitData = [];
      this.showBack = false;
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // Export API
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        const date = new Date();
        this.excelName =
          "Asset_History_Report" +
          (1 + date.getMonth()).toString().padStart(2, "0") +
          "_" + // 9
          date.getDate().toString().padStart(2, "0") +
          "_" + // 30
          date.getFullYear() +
          ".xls";
        this.export50kData = false;
        return this.assetHistoryList;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    // File name for Downloading excel
    fileName() {
      if (this.totalRecords <= 15000)
        this.excelName =
          "Asset_History_Report" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Get key of search parameter
    getMatchingKeys(item) {
      const cleanedParam = item.search_param.replace(/<\/?b>/g, "");
      const matchingKeys = [];
      for (const key in item) {
        if (key !== "search_param" && item[key] === cleanedParam) {
          matchingKeys.push(key);
        }
      }
      return matchingKeys.join(", ");
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
